import styled from 'styled-components';
import Img from 'gatsby-image';

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 12rem;

  @media (min-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const Image = styled(Img)``;
