import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'RationalTWDisplayDEMO-Light';
    src: url('fonts/3A06BC_0_0.eot');
    src: url('fonts/3A06BC_0_0.eot?#iefix') format('embedded-opentype'),
      url('fonts/3A06BC_0_0.woff2') format('woff2'),
      url('fonts/3A06BC_0_0.woff') format('woff'),
      url('fonts/3A06BC_0_0.ttf') format('truetype');
  }

  :root {
    font-size: 62.5%;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  html {
    position: relative;
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    height: 100%;
  }

  body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
    font-family: 'RationalTWDisplayDEMO-Light', sans-serif;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  p {
    margin: 0;
    padding: 0;
  }

  a {
    display: block;
    color: currentColor;
    text-decoration: none;
  }

  button {
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;

    &:focus,
    &:active {
      outline: none;
    }
  }

  ol,
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  blockquote {
    margin: 0;
    padding: 0;
  }

  cite {
    display: block;
    font-style: normal;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  input,
  select {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-family: inherit;
    border: none;
    border-radius: 0;

    &:focus,
    &:active {
      outline: none;
    }
  }

  @media (min-width: 768px) {
    body {
      font-size: 1.7rem;
    }
  }

  @media (min-width: 768px) {
    body {
      font-size: 1.8rem;
    }
  }
`;

export default GlobalStyle;
