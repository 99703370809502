import React from 'react';

import GlobalStyle from '../utils/createGlobalStyle';
import SEO from '../components/SEO/SEO';
import Header from '../components/Header/Header';
import Main from '../components/Main/Main';
import Footer from '../components/Footer/Footer';

const IndexPage = () => (
  <>
    <GlobalStyle />
    <SEO />
    <Header />
    <Main />
    <Footer />
  </>
);

export default IndexPage;
