import React from 'react';
import { Wrapper, Content, Text, Image } from './styles';

import Gallery from './Gallery/Gallery';

import Hand from '../../assets/images/hand.png';

const Main = () => (
  <Wrapper>
    <Content>
      <Text>Nare Sushi Bar es una experiencia Omakase en Buenos Aires.</Text>
      <Text>
        Omakase es una palabra japonesa que en gastronomia significa que la
        eleccion de lo que comes queda a cargo del chef.
      </Text>
      <Image src={Hand} alt="Hand" />
      <Text>
        El chef Federico Jorge crea un menu degustacion que cambia dia a dia
        dependiendo de los productos del mercado.
      </Text>
      <Text>
        Usamos pesca local y de temporada, todo se prepara en el momento para
        respetar la frescura y calidad de cada pesca.
      </Text>
    </Content>
    <Gallery />
  </Wrapper>
);

export default Main;
