import React from 'react';
import { Content, Text, Link, Icon } from './styles';

const Footer = () => (
  <Content>
    <Text>Nare Sushi Bar</Text>
    <Link
      aria-label="Instagram"
      href="https://www.instagram.com/naresushibar/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Icon />
    </Link>
  </Content>
);

export default Footer;
