import styled from 'styled-components';

export const Wrapper = styled.main`
  background-color: #000;
`;

export const Content = styled.div`
  padding: 12rem 4rem;

  @media (min-width: 768px) {
    padding: 12rem 8rem;
  }

  @media (min-width: 1024px) {
    padding: 12rem 12rem;
  }

  @media (min-width: 1440px) {
    padding: 12rem 20rem;
  }
`;

export const Text = styled.span`
  display: block;
  color: #fff;
  line-height: 1.5;

  &:nth-of-type(even) {
    margin-left: auto;
    text-align: right;
  }

  &:not(:last-child) {
    margin-bottom: 12rem;
  }

  @media (min-width: 768px) {
    max-width: 70%;
    line-height: 1.6;
  }

  @media (min-width: 1024px) {
    max-width: 60%;
    line-height: 1.7;
  }

  @media (min-width: 1440px) {
    max-width: 50%;
    line-height: 1.8;
  }
`;

export const Image = styled.img`
  width: 13rem;
  margin: 0 auto 12rem auto;

  @media (min-width: 768px) {
    width: 15rem;
  }

  @media (min-width: 1024px) {
    width: 17rem;
  }
`;
