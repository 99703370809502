import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Carousel, Slide } from './styles';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Header = () => {
  const headerImages = useStaticQuery(
    graphql`
      query {
        imageOne: file(relativePath: { eq: "images/hero/image1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageOneMobile: file(
          relativePath: { eq: "images/hero/image1-mobile.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 768, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageTwo: file(relativePath: { eq: "images/hero/image2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageTwoMobile: file(
          relativePath: { eq: "images/hero/image2-mobile.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 768, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageThree: file(relativePath: { eq: "images/hero/image3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageThreeMobile: file(
          relativePath: { eq: "images/hero/image3-mobile.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 768, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  const sourcesOne = [
    headerImages.imageOneMobile.childImageSharp.fluid,
    {
      ...headerImages.imageOne.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  const sourcesTwo = [
    headerImages.imageTwoMobile.childImageSharp.fluid,
    {
      ...headerImages.imageTwo.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  const sourcesThree = [
    headerImages.imageThreeMobile.childImageSharp.fluid,
    {
      ...headerImages.imageThree.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  const settings = {
    accesibility: false,
    arrows: false,
    autoplaySpeed: 4000,
    autoplay: true,
    cssEase: 'ease-in-out',
    dots: false,
    draggable: false,
    fade: true,
    pauseOnHover: false,
    speed: 1500,
    swipe: false,
    touchMove: false,
  };

  return (
    <Carousel {...settings}>
      <Slide fluid={sourcesOne} loading="eager" />
      <Slide fluid={sourcesTwo} loading="eager" />
      <Slide fluid={sourcesThree} loading="eager" />
    </Carousel>
  );
};

export default Header;
