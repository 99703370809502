import styled from 'styled-components';

import Instagram from '../../assets/icons/instagram.svg';

export const Content = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 12rem 4rem;

  @media (min-width: 768px) {
    padding: 12rem 8rem;
  }
`;

export const Text = styled.span`
  display: block;
  margin-bottom: 4rem;

  @media (min-width: 768px) {
    margin-bottom: 8rem;
  }
`;

export const Link = styled.a`
  cursor: pointer;
`;

export const Icon = styled(Instagram)`
  width: 2rem;
  height: 2rem;

  @media (min-width: 768px) {
    width: 2.5rem;
    height: 2.5rem;
  }
`;
