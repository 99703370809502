import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Content, Image } from './styles';

const Gallery = () => {
  const galleryImages = useStaticQuery(
    graphql`
      query {
        imageOne: file(relativePath: { eq: "images/gallery/image1.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageTwo: file(relativePath: { eq: "images/gallery/image2.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageThree: file(relativePath: { eq: "images/gallery/image3.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageFour: file(relativePath: { eq: "images/gallery/image4.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageFive: file(relativePath: { eq: "images/gallery/image5.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageSix: file(relativePath: { eq: "images/gallery/image6.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageSeven: file(relativePath: { eq: "images/gallery/image7.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        imageEight: file(relativePath: { eq: "images/gallery/image8.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  );

  return (
    <Content>
      <Image fluid={galleryImages.imageOne.childImageSharp.fluid} />
      <Image fluid={galleryImages.imageTwo.childImageSharp.fluid} />
      <Image fluid={galleryImages.imageThree.childImageSharp.fluid} />
      <Image fluid={galleryImages.imageFour.childImageSharp.fluid} />
      <Image fluid={galleryImages.imageFive.childImageSharp.fluid} />
      <Image fluid={galleryImages.imageSix.childImageSharp.fluid} />
      <Image fluid={galleryImages.imageSeven.childImageSharp.fluid} />
      <Image fluid={galleryImages.imageEight.childImageSharp.fluid} />
    </Content>
  );
};

export default Gallery;
