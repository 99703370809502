import styled from 'styled-components';

export const Wrapper = styled.header`
  position: relative;
`;

export const Content = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 12rem 4rem;

  @media (min-width: 768px) {
    padding: 12rem 8rem;
  }
`;

export const Image = styled.img`
  width: 20rem;
  margin: auto;

  @media (min-width: 768px) {
    width: 22rem;
  }

  @media (min-width: 1440px) {
    width: 24rem;
  }
`;

export const List = styled.ul`
  text-align: center;
`;

export const ListItem = styled.li`
  color: #fff;

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;
