import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import Favicon from '../../assets/favicon.png';

const SEO = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            keywords
          }
        }
      }
    `,
  );

  return (
    <Helmet
      title={site.siteMetadata.title}
      htmlAttributes={{ lang: 'es' }}
      link={[{ rel: 'shortcut icon', type: 'image/png', href: Favicon }]}
      meta={[
        {
          name: `description`,
          content: site.siteMetadata.description,
        },
        {
          property: `og:title`,
          content: site.siteMetadata.title,
        },
        {
          property: `og:description`,
          content: site.siteMetadata.description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `author`,
          content: site.siteMetadata.author,
        },
        {
          property: `keywords`,
          content: site.siteMetadata.keywords,
        },
      ]}
    />
  );
};

export default SEO;
