import styled from 'styled-components';
import Slider from 'react-slick';
import Img from 'gatsby-image';

export const Carousel = styled(Slider)`
  height: 100vh;

  & div {
    height: 100%;
  }
`;

export const Slide = styled(Img)`
  filter: brightness(75%);
`;
