import React from 'react';
import { Wrapper, Image, Content, List, ListItem } from './styles';

import Hero from './Hero/Hero';

import Logo from '../../assets/images/logo.png';

const Header = () => (
  <Wrapper>
    <Hero />
    <Content>
      <Image src={Logo} alt="Logo" />
      <List>
        <ListItem>Abrimos de Martes a Sabados</ListItem>
        <ListItem>Almuerzo 12 - 15</ListItem>
        <ListItem>Cena 20 - 23.30</ListItem>
        <ListItem>Reservas unicamente por telefono</ListItem>
        <ListItem>+54 11 4783 4165</ListItem>
        <ListItem>Echeverria 1524, CABA, Argentina</ListItem>
      </List>
    </Content>
  </Wrapper>
);

export default Header;
